.app404 {
	height: 100vh;
	width: 100vw;
	text-align: center;
	padding-top: 45vh;
	background: white;
}

.appPage {
	width: 100%;
}

.appContainer {
	max-width: 100%;
	position: relative;
	margin: 0 auto;
}
/*
@media (min-width: 2048px) {
	.appContainer {
		margin: 0 4%;
	}
}
@media (min-width: 2560px) {
	.appContainer {
		margin: 0 10%;
	}
}
*/
.inAppContent {
	min-width: 768px;
}

/* Pour bug de scroll sur ipad (2019 01) */
.modal {
	overflow-y: auto !important;
}

/* Pour éviter décalage lié à la disparition de la scrollbar qd modal bootstrap s'ouvre */
.modal-open {
	overflow: hidden;
	overflow-y: inherit;
	padding-right: 0 !important;
}

.screenWarningModalDialog {
	width: 80%;
	margin-top: 10vh;
}

.screenWarningModalDialog .modal-body {
	height: 80vh;
	font-size: 24px;
	font-weight: bold;
	color: var(--fk-color-text-standard);
	text-align: center;
	padding-left: 10%;
	padding-right: 10%;
	display: table-cell;
	vertical-align: middle;
}

.pageContent {
	min-height: 100vh;
	min-width: 768px;
	padding: calc(var(--fk-navbar-height) + 10px) 15px 60px 15px;
	background: #fafbfc;
}

.pageSection {
	background: white;
	border-radius: 6px;
	/*
	-webkit-box-shadow:
		0 2px 6px 0 rgba(0, 0, 0, 0.12),
		0 1px 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow:
		0 2px 6px 0 rgba(0, 0, 0, 0.12),
		0 1px 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	-o-box-shadow:
		0 2px 6px 0 rgba(0, 0, 0, 0.12),
		0 1px 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	box-shadow:
		0 2px 6px 0 rgba(0, 0, 0, 0.12),
		0 1px 6px 0 rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.2);
	*/
	box-shadow: var(--fk-shadow-md);
	margin: 25px auto 0;
	position: relative;
}
.pageSection:first-child {
	margin-top: 20px;
}
.pageSectionTitle {
	height: 36px;
	font-size: 18px;
	font-weight: 500;
	background: #f1f5f8;
	color: var(--fk-color-text-standard);
	border-top-left-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-webkit-border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	-moz-border-radius-topright: 4px;
	-webkit-border-top-right-radius: 4px;
	padding: 5px 0 0 10px;
}
.pageSectionContent {
	padding: 30px 15px 40px 15px;
}

/*********************************** Nvelle interface */

.pageContent2 {
	/* padding: 50px 0 0; */
	padding: var(--fk-navbar-height) 0 0;
}

.appMainContent {
	padding: 0 0 0 var(--fk-side-menu-width-expanded);
	position: relative;
	-webkit-transition: padding 0.6s ease;
	-moz-transition: padding 0.6s ease;
	-ms-transition: padding 0.6s ease;
	-o-transition: padding 0.6s ease;
	transition: padding 0.6s ease;
}
.appMainContent.menuCollapsed,
.appMainContent.menuOverflow {
	padding-left: var(--fk-side-menu-width-collapsed);
}
.appMainContent .appMenuLeft {
	/* height: calc(100vh - 50px); */
	height: calc(100vh - var(--fk-navbar-height));
	width: var(--fk-side-menu-width-expanded);
	/* background: #16395F; */
	/* background: linear-gradient(to bottom right, #16395F, #1B4775); */
	/* background: var(--fk-color-bright-blue); */
	background: linear-gradient(to bottom, var(--fk-side-menu-main-bg-color), var(--fk-side-menu-main-bg-color) 50%, var(--fk-side-menu-secondary-bg-color));
	position: absolute;
	top: 0;
	left: 0;
	transition: width 0.5s ease;
	z-index: 1001;
}

.appMainContent.menuOverflow .appMenuLeft {
	height: 100%;
}
@media (min-width: 768px) {
	.appMainContent.menuCollapsed .appMenuLeft {
		position: fixed;
		/* top: 50px; */
		top: var(--fk-navbar-height);
	}
}
@media (min-width: 963px) {
	.appMainContent .appMenuLeft {
		position: fixed;
		/* top: 50px; */
		top: var(--fk-navbar-height);
	}
}
.appMainContent.menuCollapsed .appMenuLeft {
	width: var(--fk-side-menu-width-collapsed);
}
/*
@media (min-width: 2048px) {
	.appMainContent .appMenuLeft {
		left: 4%;
	}
}
@media (min-width: 2560px) {
	.appMainContent .appMenuLeft {
		left: 10%;
	}
}
*/

@keyframes menuElementFadeIn {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	1% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}
@keyframes menuElementFadeOut {
	0% {
		opacity: 1;
		visibility: visible;
	}
	99% {
		opacity: 0;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

.appMainContent .appMenuLeft > div {
	height: 100%;
	width: 100%;
	position: relative;
}

.appMainContent .appContentRight {
	min-height: calc(100vh - var(--fk-navbar-height));
	width: 100%;
	/* min-width: 828px; */
	min-width: 880px;
	background: #fafbfc;
	padding: var(--fk-app-content-right-padding-top) 10px var(--fk-app-content-right-padding-bottom);
	display: inline-block;
	vertical-align: top;
}

.appMainContent .appMenuLeft .appMenuLogoWrapper {
	height: 50px;
	padding-left: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
/*
nav.appNavbar2 .navbar-brand {
	max-width: calc(100% - 60px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
*/

.appMainContent .appMenuLeft .appMenuLogoWrapper .appLogo {
	max-width: calc(100% - 40px);
	animation: menuElementFadeIn 1s ease;
}
.appMainContent.menuCollapsed .appMenuLeft .appMenuLogoWrapper .appLogo {
	animation: menuElementFadeOut 0.2s ease;
	animation-fill-mode: forwards;
}

.appMainContent .appMenuLeft .appMenuLogoWrapper .appLogo .wlBrandWrapper {
	max-width: 100%;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper .appLogo .wlBrandWrapper > * {
	flex-shrink: 1;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper .appLogo .wlBrandWrapper > a {
	flex-shrink: 4;
}

.appMainContent .appMenuLeft .appMenuLogoWrapper .navLogo {
}
.appMainContent .appMenuLeft .appMenuLogoWrapper .navLogo svg {
	height: 20px;
	width: auto;
	max-width: 100%;
	vertical-align: middle;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper .navLogo svg path {
	fill: white !important;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper img {
	height: auto;
	max-height: 30px;
	width: auto;
	max-width: 100%;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper {
	font-size: 16px;
	font-weight: 500;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper,
.appMainContent .appMenuLeft .appMenuLogoWrapper:hover,
.appMainContent .appMenuLeft .appMenuLogoWrapper:focus,
.appMainContent .appMenuLeft .appMenuLogoWrapper a,
.appMainContent .appMenuLeft .appMenuLogoWrapper a:visited,
.appMainContent .appMenuLeft .appMenuLogoWrapper a:hover,
.appMainContent .appMenuLeft .appMenuLogoWrapper a:focus {
	color: white;
	text-decoration: none;
	cursor: default;
}
.appMainContent .appMenuLeft .appMenuLogoWrapper a:hover,
.appMainContent .appMenuLeft .appMenuLogoWrapper a:focus {
	cursor: pointer;
}

.appMainContent .appMenuLeft button#appMenuCollapse {
	height: 40px;
	width: 40px;
	color: white;
	font-size: 24px;
	opacity: 0.8;
	background: none;
	border: none;
	outline: none;
	padding: 0;
	position: absolute;
	top: 5px;
	/* right: 15px; */
	right: 5px;
}
.appMainContent .appMenuLeft button#appMenuCollapse:hover {
	opacity: 1;
}

/*************************************************************/

.contentTitle {
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 30px;
}

.Actions {
	/*
	margin-top: 10px;
  padding-right: 0;
	*/
	position: fixed;
	bottom: 10px;
	right: 100px;
	background: none;
}
.Actions button {
	width: 150px;
	margin: 0 0 0 10px;
	float: right;
}

.modal {
	overflow-y: auto;
}
.modal-dialog {
	width: 600px; /* par défaut */
	max-width: 100%;
	-webkit-transform: none !important;
	-ms-transform: none !important;
	-o-transform: none !important;
	transform: none !important;
	border-radius: 0.3rem;
	/* -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2); */
	/* -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2); */
	/* -o-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2); */
	/* box-shadow: 0 2px 10px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2); */
}
.modal-header {
	text-align: center;
	padding: 10px;
	border-bottom: 1px solid #e3e7eb;
	align-items: center;
}
.modal-title {
	width: 100%;
	font-size: 1.1rem;
}
.modal-header > button.close,
.modal-header .btn-close {
	outline: none !important;
	box-shadow: none !important;
	padding: 0.5rem;
	margin: 0 !important;
}
.modal-content {
	border: 1px solid #b8b8ba;
}
.modal-footer {
	border: none;
	justify-content: center;
}

.ModalActions {
	text-align: center;
}

.ModalActions button {
	min-width: 130px;
	font-size: 14px;
	margin: 0 5px;
	display: inline-block;
}

.genericInformationModalDialog {
	width: 500px;
	margin-top: 20vh;
}
.genericInformationModalDialog .modal-body {
	padding: 30px 20px;
	text-align: center;
}
.genericInformationModalDialog .modal-footer {
	border: none;
	text-align: center;
}

.bi {
	/* font-size: 1rem; */
}

.etbBtn {
	color: var(--fk-color-btn-link-clickable);
	background: none;
	border: none;
	padding: 0;
	outline: none;
}

.b-main-action {
	--default-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
	--hover-box-shadow-main: 0 1px 9px 0 rgba(0, 0, 0, 0.12), 0 1px 7px -1px rgba(0, 0, 0, 0.2);
	--hover-box-shadow-alt: 0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 4px -1px rgba(0, 0, 0, 0.2);
}

.b-main-action {
	font-size: 14px;
	color: white;
	background-color: var(--fk-color-btn-link-clickable);
	border: 1px solid transparent;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 0.4em 0.8em;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	outline-style: none !important;
	user-select: none;
	box-shadow: var(--default-box-shadow);
	transition:
		color 0.15s ease-in-out,
		background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	/* display: inline-block; */
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.b-main-action.outlined {
	color: var(--fk-color-btn-link-clickable);
	background: none;
	border-color: var(--fk-color-btn-link-clickable);
}

.b-main-action:disabled,
.b-main-action.disabled,
.b-main-action:disabled:hover,
.b-main-action:disabled:focus,
.b-main-action:disabled:active,
.b-main-action:disabled:active:hover,
.b-main-action:disabled:active:focus,
.b-main-action.disabled:hover,
.b-main-action.disabled:focus,
.b-main-action.disabled:active,
.b-main-action.disabled:active:hover,
.b-main-action.disabled:active:focus {
	color: #b8b8ba;
	background: none;
	border-color: #b8b8ba;
	/* border: none; */
	/* border-color: transparent; */
	cursor: default;
}
.b-main-action:disabled,
.b-main-action.disabled {
	opacity: 1;
	pointer-events: auto;
}

.b-main-action:not(:disabled):not(.disabled):hover,
.b-main-action:not(:disabled):not(.disabled):focus,
.b-main-action:not(:disabled):not(.disabled):active,
.b-main-action:not(:disabled):not(.disabled):active:hover,
.b-main-action:not(:disabled):not(.disabled):active:focus,
.b-main-action:not(:disabled):not(.disabled).active,
.open .dropdown-toggle.b-main-action:not(:disabled):not(.disabled) {
	color: white;
	background-color: var(--fk-color-btn-link-clickable);
	border-color: transparent;
	/* box-shadow: 0 1px 8px 0 rgba(0,0,0, 0.12), 0 1px 6px -1px rgba(0,0,0, 0.2); */
	box-shadow: var(--hover-box-shadow-main);
	cursor: pointer;
}

.b-main-action.b-main-alt:not(:disabled):not(.disabled) {
	background-color: #e3e7eb;
	border-color: #b8b8ba;
	color: var(--fk-color-text-standard);
}
.b-main-action.b-main-alt:not(:disabled):not(.disabled):hover,
.b-main-action.b-main-alt:not(:disabled):not(.disabled):focus,
.b-main-action.b-main-alt:not(:disabled):not(.disabled):active,
.b-main-action.b-main-alt:not(:disabled):not(.disabled):active:hover,
.b-main-action.b-main-alt:not(:disabled):not(.disabled):active:focus {
	background-color: #e3e7eb;
	border-color: #b8b8ba;
	color: var(--fk-color-text-standard);
	/* box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.12), 0 1px 4px -1px rgba(0,0,0, 0.2); */
	box-shadow: var(--hover-box-shadow-alt);
}

.btn-lg,
.btn-group-lg > .btn {
	padding: 14px 16px;
	font-size: 20px;
	line-height: 1.33;
}

.btn-round {
	height: 34px !important;
	width: 34px !important;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	padding-top: 2px;
	padding-left: 1px;
}

.btn-round.green {
	background-color: var(--fk-color-text-success);
	border-color: var(--fk-color-text-success);
}

.btn-round.green:hover,
.btn-round.green:focus,
.btn-round.green:active {
	background-color: #008b80;
}

.btn-round svg {
	height: 28px;
	width: 28px;
}

.btn-round svg circle {
	fill: transparent !important;
}

.btn-round path {
	fill: #ffffff !important;
}

.btn-round .bi {
	font-size: 18px;
}

button.newEtbBtn {
	height: 52px;
	font-size: 16px;
	color: white;
	border-radius: 6px;
	background: linear-gradient(83deg, #2534a8, #3142c6);
	border: none;
	padding: 0 30px;
	outline: none;
	box-shadow: none;
	margin: 0 auto;
}
button.newEtbBtn:active,
button.newEtbBtn:focus {
	outline: none;
	box-shadow: none;
}
button.newEtbBtn i {
	margin-left: 10px;
}
button.newEtbBtn .spinner {
	border-top-color: white;
	border-right-color: white;
	border-bottom-color: white;
}
button.newEtbBtn:disabled {
	opacity: 1;
}

.input-group {
	margin-bottom: 1.25em;
	width: 100%;
}

/*
.form-control {
  font-size: 14px;
}
*/

.isvg svg {
	vertical-align: top;
}

.popover,
.tooltip {
	font-family: inherit;
	/* padding: 0; */
	background: none;
	/* -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 1px 6px 0 rgba(0,0,0,0.08), 0 2px 3px -1px rgba(0,0,0,0.16); */
	/* -moz-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 1px 6px 0 rgba(0,0,0,0.08), 0 2px 3px -1px rgba(0,0,0,0.16); */
	/* -o-box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 1px 6px 0 rgba(0,0,0,0.08), 0 2px 3px -1px rgba(0,0,0,0.16); */
	/* box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 1px 6px 0 rgba(0,0,0,0.08), 0 2px 3px -1px rgba(0,0,0,0.16); */
	border-radius: 4px;
}

.tooltip.show {
	opacity: 1;
}
.tooltip.bs-tooltip-top .tooltip-arrow:before,
.tooltip.bs-tooltip-top .tooltip-arrow:after {
	border-top-color: #b8b8ba;
}
.tooltip.bs-tooltip-end .tooltip-arrow:before,
.tooltip.bs-tooltip-end .tooltip-arrow:after {
	border-right-color: #b8b8ba;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow:before,
.tooltip.bs-tooltip-bottom .tooltip-arrow:after {
	border-bottom-color: #b8b8ba;
}
.tooltip.bs-tooltip-start .tooltip-arrow:before,
.tooltip.bs-tooltip-start .tooltip-arrow:after {
	border-left-color: #b8b8ba;
}
.tooltip .tooltip-inner,
.popover .popover-body {
	max-height: 60vh;
	color: var(--fk-color-text-standard);
	background: white;
	-webkit-box-shadow:
		0 8px 10px -3px rgba(0, 0, 0, 0.1),
		0 3px 6px 1px rgba(0, 0, 0, 0.08),
		0 2px 3px -1px rgba(0, 0, 0, 0.16);
	-moz-box-shadow:
		0 8px 10px -3px rgba(0, 0, 0, 0.1),
		0 3px 6px 1px rgba(0, 0, 0, 0.08),
		0 2px 3px -1px rgba(0, 0, 0, 0.16);
	-o-box-shadow:
		0 8px 10px -3px rgba(0, 0, 0, 0.1),
		0 3px 6px 1px rgba(0, 0, 0, 0.08),
		0 2px 3px -1px rgba(0, 0, 0, 0.16);
	box-shadow:
		0 8px 10px -3px rgba(0, 0, 0, 0.1),
		0 3px 6px 1px rgba(0, 0, 0, 0.08),
		0 2px 3px -1px rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	padding: 0.7em;
	overflow-y: auto;
}
.tooltip .tooltip-inner::-webkit-scrollbar,
.popover .popover-body::-webkit-scrollbar {
	width: 6px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	background-color: #f5f5f5;
}
.tooltip .tooltip-inner::-webkit-scrollbar-thumb,
.popover .popover-body::-webkit-scrollbar-thumb {
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	background-color: #b8b8ba;
}

.actionTooltip {
	font-size: 13px;
}

p.link {
	margin-top: 10px;
}
/*
p.link a,
p.link a:focus,
p.link a:hover,
p.link a:visited {
	color: var(--fk-color-btn-link-clickable);
	text-decoration: none;
	outline: none;
	cursor: pointer;
}

p.link a.disabled {
	color: #B8B8BA;
	cursor: not-allowed;
}

p.link a:hover {
	text-decoration: underline;
}
*/

/******************************************* Navbars */

/*********** Générique */

.navbar-nav > li > .dropdown-menu {
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}

/*********** Homepage */

nav.appNavbar2 {
	height: var(--fk-navbar-height);
	width: 100%;
	border: 0px;
	padding: 0.5rem;
	/* z-index: 9999; */
}

nav.appNavbar2 .navbar-collapse {
	justify-content: flex-end;
}

nav.appNavbar2 .navbar-nav {
	align-items: center;
}
nav.appNavbar2 .navbar-collapse.show .navbar-nav {
	align-items: flex-end;
}

nav.appNavbar2 .navbar-nav a,
nav.appNavbar2 .navbar-nav a:focus,
nav.appNavbar2 .navbar-nav a:hover,
nav.appNavbar2 .navbar-nav a:visited {
	text-decoration: none;
	outline: none;
	background: none;
}
nav.appNavbar2 .navbar-nav a.nav-link {
	text-align: right;
	padding-left: 0.7rem;
	padding-right: 0.7rem;
	white-space: nowrap;
	vertical-align: middle;
}

nav.appNavbar2 .navbar-brand {
	max-width: calc(100% - 60px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
nav.appNavbar2 .navbar-brand .navLogo svg {
	height: 12px;
	width: auto;
	max-width: 100%;
}
nav.appNavbar2 .navbar-brand img {
	height: auto;
	max-height: 30px;
	width: auto;
	max-width: 100%;
}
@media (min-width: 320px) {
	nav.appNavbar2 .navbar-brand .navLogo svg {
		max-width: 120px;
	}
	nav.appNavbar2 .navbar-brand img {
		max-width: 120px;
	}
}
@media (min-width: 1024px) {
	nav.appNavbar2 .navbar-brand .navLogo svg {
		height: 22px;
	}
}
nav.appNavbar2 .navbar-brand {
	font-size: 16px;
	font-weight: 500;
}

nav.appNavbar2 .connection {
	min-width: 105px;
}
nav.appNavbar2 .connection .userNavbarLoginTitle {
	max-width: 200px;
	display: inline-block;
	vertical-align: middle;
}
nav.appNavbar2 .connection a .userNavbarLoginTitle {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media (min-width: 820px) {
	nav.appNavbar2 .connection .userNavbarLoginTitle {
		max-width: 250px;
	}
}
@media (min-width: 1024px) {
	nav.appNavbar2 .connection {
		min-width: 130px;
	}
	nav.appNavbar2 .connection .userNavbarLoginTitle {
		max-width: 320px;
	}
}
@media (min-width: 1280px) {
	nav.appNavbar2 .connection {
		min-width: 135px;
	}
	nav.appNavbar2 .connection .userNavbarLoginTitle {
		max-width: 390px;
	}
}
@media (min-width: 1920px) {
	nav.appNavbar2 .connection .userNavbarLoginTitle {
		max-width: none;
	}
}

nav.appNavbar2:not(.loggedIn) .connection {
	display: none;
}
/*
nav.appNavbar2 .connection .isvg {
	height: 16px;
	width: 16px;
	line-height: 1em;
	padding: 0;
	margin-right: 8px;
	display: inline-block;
	vertical-align: middle;
}
nav.appNavbar2 .connection svg {
	height: 16px;
	width: 16px;
	vertical-align: baseline;
}
*/
nav.appNavbar2 .connection i {
	font-size: 1.2em;
	margin-right: 8px;
	vertical-align: middle;
}

@media (min-width: 900px), (min-height: 900px) {
	nav.appNavbar2:not(.loggedIn) .connection {
		display: block;
	}
}

/* Fonts */

nav.appNavbar2 .navbar-nav a {
	font-size: 10px;
	font-weight: 300;
	text-transform: uppercase;
}
@media (min-width: 1024px) {
	nav.appNavbar2 .navbar-nav a {
		font-size: 14px;
	}
}
@media (min-width: 1280px) {
	nav.appNavbar2 .navbar-nav a {
		font-size: 16px;
	}
}

nav.appNavbar2.loggedIn .navbar-nav .connection a.nav-link {
	font-weight: 600;
	text-transform: none;
}
nav.appNavbar2 .navbar-nav .connection a.dropdown-toggle.nav-link:after {
	vertical-align: middle;
}

nav.appNavbar2 .navbar-nav .dropdown .dropdown-menu a {
	max-width: 36vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
nav.appNavbar2 .navbar-nav .dropdown .dropdown-menu .disabled a {
	color: #b8b8ba;
}

/* Couleurs */

nav.appNavbar2.loggedIn {
	background-color: var(--fk-navbar-color);
}
/*
.app-wl nav.appNavbar2.loggedIn {
  background-color: #2B5E93;
}
*/
/*
nav.appNavbar2.-old.loggedIn {
  background-color: #2B5E93;
}
*/

nav.appNavbar2 button.navbar-toggler {
	height: 34px;
	width: 34px;
	border: 1px solid white;
	background: none;
	padding: 2px 4px;
}
nav.appNavbar2 button.navbar-toggler i {
	font-size: 24px;
	color: white;
}

nav.appNavbar2.loggedIn .navbar-collapse.collapse.show {
	/* background-color: #2B5E93; */
	background-color: var(--fk-navbar-color);
	margin: 0 -0.5rem;
}
/*
.app-wl nav.appNavbar2.loggedIn .navbar-collapse.collapse.show {
  background-color: #2B5E93;
}
*/
/*
nav.appNavbar2.-old.loggedIn .navbar-collapse.collapse.show {
  background-color: #2B5E93;
}
*/
nav.appNavbar2:not(.loggedIn) .navbar-collapse.collapse.show {
	background-color: rgb(255, 255, 255, 0.14);
}

@media (min-width: 768px) {
	nav.appNavbar2 .navbar-collapse {
		background: none;
	}
}

nav.appNavbar2 .navbar-brand,
nav.appNavbar2 .navbar-brand:hover,
nav.appNavbar2 .navbar-brand:focus,
nav.appNavbar2 .navbar-brand a,
nav.appNavbar2 .navbar-brand a:visited {
	color: white;
	text-decoration: none;
	cursor: default;
}
nav.appNavbar2 .navbar-brand a:hover,
nav.appNavbar2 .navbar-brand a:focus {
	color: #6bc9f2;
	text-decoration: none;
	cursor: pointer;
}
nav.appNavbar2 .navbar-brand .navLogo svg {
	vertical-align: middle;
}
nav.appNavbar2 .navbar-brand .navLogo svg path {
	fill: white !important;
}
nav.appNavbar2 .navbar-brand a:hover .navLogo svg path {
	fill: #6bc9f2 !important;
}
nav.appNavbar2 .navbar-brand img {
	vertical-align: middle;
}
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav a.nav-link,
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav a.nav-link:focus,
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav a.nav-link:visited {
	color: white;
}
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav a.nav-link:hover {
	color: #6bc9f2;
}
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav .connection svg path {
	fill: white !important;
}
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav .connection.nav-link:hover svg path,
nav.appNavbar2 .navbar-collapse.collapse .navbar-nav .connection a:hover svg path {
	fill: #6bc9f2 !important;
}
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu {
	background-color: white;
	border-radius: 3px;
	padding: 5px 0;
	box-shadow: 0 4px 8px rgb(0, 0, 0, 0.12);
}
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu a,
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu a:focus,
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu a:visited {
	color: #16395f;
	text-align: left;
}
nav.appNavbar2 .navbar-collapse.collapse.show .navbar-nav .dropdown .dropdown-menu {
	background: none;
	box-shadow: 0 4px 8px rgb(255, 255, 255, 0.12);
}
nav.appNavbar2 .navbar-collapse.collapse.show .navbar-nav .dropdown .dropdown-menu a,
nav.appNavbar2 .navbar-collapse.collapse.show .navbar-nav .dropdown .dropdown-menu a:focus,
nav.appNavbar2 .navbar-collapse.collapse.show .navbar-nav .dropdown .dropdown-menu a:visited {
	color: white;
	text-align: right;
}
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu a:hover {
	color: var(--fk-color-btn-link-clickable);
}
nav.appNavbar2 .navbar-collapse.collapse:not(.show) .navbar-nav .dropdown .dropdown-menu .disabled a {
	color: #b8b8ba;
	cursor: default;
}

/******************************************* xxxxxx */

/*
.userTitle .bi {
	margin-right: 10px;
}
*/

textarea {
	resize: none;
}

.etbBlue {
	color: var(--fk-color-btn-link-clickable) !important;
}
.etbGreen {
	color: var(--fk-color-text-success-light) !important;
}
.etbDarkGreen {
	color: var(--fk-color-text-success) !important;
}
.etbRed {
	color: var(--fk-color-error) !important;
}
.etbGrey {
	color: #b8b8ba !important;
}
.etbLightGrey {
	color: #e3e7eb !important;
}
.etbPink {
	color: #ec008c !important;
}
.white {
	color: white !important;
}
.black {
	color: black !important;
}
.light {
	font-weight: 300 !important;
}
.lighter {
	font-weight: 100 !important;
}
.bold {
	font-weight: bold !important;
}
.weight-normal {
	font-weight: normal !important;
}
.uppercase {
	text-transform: uppercase !important;
}
.italic {
	font-style: italic !important;
}
.style-normal {
	font-style: normal !important;
}
.superscript {
	vertical-align: super;
}
.leftAlign {
	text-align: left;
}
.rightAlign {
	text-align: right;
}
.centerAlign {
	text-align: center;
}
.noWrap {
	white-space: nowrap;
}
.strikeThrough {
	text-decoration: line-through;
}
.noWrapEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.overflowVisible {
	overflow: visible !important;
}
.block {
	display: block;
}
.inlineBlock {
	display: inline-block;
}
.displayNone {
	display: none;
}
.displayContents {
	display: contents;
}
.flex {
	display: flex;
}
.inlineFlex {
	display: inline-flex;
}
.justifyFlexStart {
	justify-content: flex-start !important;
}
.justifyFlexEnd {
	justify-content: flex-end !important;
}
.justifySpaceAround {
	justify-content: space-around !important;
}
.justifySpaceBetween {
	justify-content: space-between !important;
}
.justifySpaceEvenly {
	justify-content: space-evenly !important;
}
.justifyCenter {
	justify-content: center !important;
}
.alignItemsFlexStart {
	align-items: flex-start !important;
}
.alignItemsFlexEnd {
	align-items: flex-end !important;
}
.alignItemsCenter {
	align-items: center !important;
}
.alignItemsBaseline {
	align-items: baseline !important;
}
.alignItemsStretch {
	align-items: stretch !important;
}
.flexWrap {
	flex-wrap: wrap !important;
}
.flexDirectionRow {
	flex-direction: row;
}
.flexDirectionColumn {
	flex-direction: column;
}
.flexGap05 {
	gap: 0.5em;
}
.flexGap10 {
	gap: 1em;
}
.flexGap20 {
	gap: 2em;
}
.flexGrow1 {
	flex-grow: 1;
}
.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}
.verticalAlignTop {
	vertical-align: top !important;
}
.verticalAlignBottom {
	vertical-align: bottom !important;
}
.verticalAlignMiddle {
	vertical-align: middle !important;
}
.verticalAlignBaseline {
	vertical-align: baseline !important;
}

.positionRelative {
	position: relative;
}
.positionAbsolute {
	position: absolute;
}

.fontSize20 {
	font-size: 2em !important;
}
.fontSize18 {
	font-size: 1.8em !important;
}
.fontSize16 {
	font-size: 1.6em !important;
}
.fontSize14 {
	font-size: 1.4em !important;
}
.fontSize12 {
	font-size: 1.2em !important;
}
.fontSize11 {
	font-size: 1.1em !important;
}
.fontSize10 {
	font-size: 1em !important;
}
.fontSize09 {
	font-size: 0.9em !important;
}
.fontSize08 {
	font-size: 0.8em !important;
}
.fontSize07 {
	font-size: 0.7em !important;
}

.width-100 {
	width: 100%;
}
.width-95 {
	width: 95%;
}
.width-90 {
	width: 90%;
}
.width-80 {
	width: 80%;
}
.width-75 {
	width: 75%;
}
.width-70 {
	width: 70%;
}
.width-60 {
	width: 60%;
}
.width-50 {
	width: 50%;
}
.width-40 {
	width: 40%;
}
.width-30 {
	width: 30%;
}
.width-25 {
	width: 25%;
}
.width-20 {
	width: 20%;
}
.width-10 {
	width: 10%;
}
.widthMinContent {
	width: min-content;
}

.marginTopAuto {
	margin-top: auto;
}
.marginTop02 {
	margin-top: 0.2em;
}
.marginTop05 {
	margin-top: 0.5em;
}
.marginTop10 {
	margin-top: 1em;
}
.marginTop15 {
	margin-top: 1.5em;
}
.marginTop20 {
	margin-top: 2em;
}
.marginTop25 {
	margin-top: 2.5em;
}
.marginTop30 {
	margin-top: 3em;
}
.marginTop40 {
	margin-top: 4em;
}

.marginBottomAuto {
	margin-bottom: auto;
}
.marginBottom02 {
	margin-bottom: 0.2em;
}
.marginBottom05 {
	margin-bottom: 0.5em;
}
.marginBottom10 {
	margin-bottom: 1em;
}
.marginBottom15 {
	margin-bottom: 1.5em;
}
.marginBottom20 {
	margin-bottom: 2em;
}
.marginBottom25 {
	margin-bottom: 2.5em;
}
.marginBottom30 {
	margin-bottom: 3em;
}
.marginBottom40 {
	margin-bottom: 4em;
}

.marginLeftAuto {
	margin-left: auto;
}
.marginLeft05 {
	margin-left: 0.5em;
}
.marginLeft10 {
	margin-left: 1em;
}
.marginLeft15 {
	margin-left: 1.5em;
}
.marginLeft20 {
	margin-left: 2em;
}
.marginLeft30 {
	margin-left: 3em;
}

.marginRightAuto {
	margin-right: auto;
}
.marginRight05 {
	margin-right: 0.5em;
}
.marginRight10 {
	margin-right: 1em;
}
.marginRight15 {
	margin-right: 1.5em;
}
.marginRight20 {
	margin-right: 2em;
}
.marginRight30 {
	margin-right: 3em;
}

.paddingTop02 {
	padding-top: 0.2em;
}
.paddingTop05 {
	padding-top: 0.5em;
}
.paddingTop10 {
	padding-top: 1em;
}
.paddingTop15 {
	padding-top: 1.5em;
}
.paddingTop20 {
	padding-top: 2em;
}
.paddingTop30 {
	padding-top: 3em;
}
.paddingTop40 {
	padding-top: 4em;
}

.paddingBottom02 {
	padding-bottom: 0.2em;
}
.paddingBottom05 {
	padding-bottom: 0.5em;
}
.paddingBottom10 {
	padding-bottom: 1em;
}
.paddingBottom15 {
	padding-bottom: 1.5em;
}
.paddingBottom20 {
	padding-bottom: 2em;
}
.paddingBottom30 {
	padding-bottom: 3em;
}
.paddingBottom40 {
	padding-bottom: 4em;
}

.paddingLeft05 {
	padding-left: 0.5em !important;
}
.paddingLeft10 {
	padding-left: 1em !important;
}
.paddingLeft20 {
	padding-left: 2em !important;
}
.paddingLeft30 {
	padding-left: 3em !important;
}

.paddingRight05 {
	padding-right: 0.5em !important;
}
.paddingRight10 {
	padding-right: 1em !important;
}
.paddingRight20 {
	padding-right: 2em !important;
}
.paddingRight30 {
	padding-right: 3em !important;
}

.cursorPointer {
	cursor: pointer;
}

i.marginLeft {
	margin-left: 0.5em;
}
i.marginRight {
	margin-right: 0.5em;
}

i.custom {
	height: 1em;
	width: 1em;
	background-position: center center;
	display: block;
}

button.ok {
	margin-left: auto;
	margin-right: auto;
}

.infoNote {
	/* color: var(--fk-color-medium-grey); */
	color: rgba(var(--fk-color-text-standard-rgb), 0.7);
	font-size: 0.9em;
}

.color-emphasis {
	color: var(--fk-color-text-emphasis);
}
.color-clickable {
	color: var(--fk-color-btn-link-clickable);
}
.color-medium-grey {
	color: var(--fk-color-medium-grey);
}
.color-light-grey {
	color: var(--fk-color-light-grey);
}
.color-warning {
	color: var(--fk-color-warning);
}
/*
button.recallOnboarding {
	height: 22px;
	width: 22px;
	font-size: 18px;
	margin: 0;
  border: none;
	outline: none;
	background: none;
	color: var(--fk-color-btn-link-clickable);
	padding: 0;
	position: absolute;
	top: 0;
	right: 0;
}
*/

/*
.iconPlus {
	height: 40px;
	width: 40px;
	border: 1px solid var(--fk-color-btn-link-clickable);
	border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
	padding-top: 4px;
	display: inline-block;
}

.iconPlus > div {
	width: 40px;
	font-size: 60px;
	color: var(--fk-color-btn-link-clickable);
	text-align: center;
}
*/

.iconPlus {
	height: 40px;
	width: 40px;
	border: 1px solid var(--fk-color-btn-link-clickable);
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	display: inline-block;
	position: relative;
	margin: 0 10px -14px 10px;
}
.iconPlus:before,
.iconPlus:after {
	content: "";
	position: absolute;
	background-color: var(--fk-color-btn-link-clickable);
}
.iconPlus:before {
	top: 15%;
	left: 50%;
	height: 70%;
	width: 2px;
	margin-left: -1px;
}
.iconPlus:after {
	top: 50%;
	left: 15%;
	height: 2px;
	width: 70%;
	margin-top: -1px;
}

/**** InfoTips ****/

.infoPopover {
	border: 1px solid #b8b8ba;
	z-index: 9999;
}

.infoPopover.xsmall {
	min-width: 100px;
}
.infoPopover.small {
	min-width: 200px;
}
.infoPopover.medium {
	min-width: 350px;
}
.infoPopover.large {
	min-width: 500px;
}
.infoPopover.xlarge {
	min-width: 760px;
}

@media (min-width: 1280px) {
	.infoPopover.xsmall {
		min-width: 150px;
	}
	.infoPopover.small {
		min-width: 400px;
	}
	.infoPopover.medium {
		min-width: 600px;
	}
	.infoPopover.large {
		min-width: 750px;
	}
	.infoPopover.xlarge {
		min-width: 1100px;
	}
}

.infoPopover.bs-popover-top .popover-arrow {
	border-top-color: #b8b8ba !important;
}

.infoPopover.bs-popover-end .popover-arrow {
	border-right-color: #b8b8ba !important;
}

.infoPopover.bs-popover-bottom .popover-arrow {
	border-bottom-color: #b8b8ba !important;
}

.infoPopover.bs-popover-start .popover-arrow {
	border-left-color: #b8b8ba !important;
}

.infoPopover .spinner {
	font-size: 0.16em;
}

.infotip {
	font-size: 16px;
	color: #b8b8ba;
	margin-left: 5px;
	cursor: pointer;
}
.infotip:hover {
	color: var(--fk-color-btn-link-clickable);
}
.infotip.sm {
	font-size: 12px;
}
/*
.infotip i.bi {
	font-size: 1.2em !important;
}
*/

button.infoTipBtn {
	background: none;
	outline: none;
	border: none;
}

/******************/

input.numberFormat::-ms-clear {
	display: none;
}

input.textInput {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid var(--fk-color-inactive);
}
input.textInput:disabled {
	border-color: var(--fk-color-disabled);
}
input.textInput:not(:disabled):hover,
input.textInput:not(:disabled):focus {
	border-color: var(--fk-color-btn-link-clickable);
}
input.textInput::-webkit-input-placeholder {
	color: var(--fk-color-inactive);
	font-style: italic;
}
input.textInput::-moz-placeholder {
	color: var(--fk-color-inactive);
	font-style: italic;
}
input.textInput:-moz-placeholder {
	color: var(--fk-color-inactive);
	font-style: italic;
}
input.textInput:-ms-input-placeholder {
	color: var(--fk-color-inactive);
	font-style: italic;
}


/******************/

.backToTopBtn {
	position: fixed;
	bottom: 20px;
	right: 70px;
	z-index: 6;
	opacity: 0.35;
}

.backToTopBtn,
.backToTopBtn:hover,
.backToTopBtn:focus,
.backToTopBtn:active {
	outline: none;
	background: none;
	border: none;
}

.backToTopBtn:hover {
	opacity: 0.8;
}

.backToTopBtn .bi {
	font-size: 50px;
	color: var(--fk-color-btn-link-clickable);
}

.backToTopBtn svg {
	height: 40px;
	width: 40px;
}

@media (min-width: 375px) {
	.backToTopBtn {
		bottom: 18px;
		right: 90px;
	}

	.backToTopBtn svg {
		height: 50px;
		width: 50px;
	}
}

.backToTopBtn path {
	fill: var(--fk-color-btn-link-clickable) !important;
}

button.backToAppTopBtn {
	height: 34px;
	width: 50px;
	opacity: 0.35;
}
.backToAppTopBtn,
.backToAppTopBtn:hover,
.backToAppTopBtn:focus,
.backToAppTopBtn:active {
	outline: none;
	background: none;
	border: none;
}
.backToAppTopBtn:hover {
	opacity: 0.8;
}
.backToAppTopBtn .bi {
	font-size: 30px;
	color: var(--fk-color-btn-link-clickable);
}
.backToAppTopBtn svg {
	height: 30px;
	width: 30px;
}
.backToAppTopBtn path {
	fill: var(--fk-color-btn-link-clickable) !important;
}

/************/

.spinnerPageLayer {
	height: 100%;
	width: 100%;
	background: #fff;
	opacity: 0.85;
	text-align: center;
	padding-top: 49vh;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
}
.spinnerPageLayer.show {
	display: block;
	z-index: 1000;
}

.spinnerProjectRightLayer {
	height: calc(100% + 35px);
	width: 100%;
	background: #fff;
	opacity: 0.85;
	text-align: center;
	padding-top: 40vh;
	position: absolute;
	top: -15px;
	left: 0;
	display: none;
}
.spinnerProjectRightLayer .spinner {
	display: block;
	margin: 10px auto;
}
.spinnerProjectRightLayer.show {
	display: block;
	z-index: 1000;
}

.spinnerSectionLayer {
	height: 100%;
	width: 100%;
	background: #fff;
	opacity: 0.85;
	text-align: center;
	padding-top: 100px;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}
.spinnerSectionLayer.show {
	display: block;
	z-index: 1000;
}

.spinnerContentRightLayer {
	/* height: calc(100vh - 50px); */
	height: calc(100vh - var(--fk-navbar-height));
	width: calc(100% - var(--fk-side-menu-width-expanded));
	background: #fff;
	opacity: 0.85;
	text-align: center;
	padding-top: 40vh;
	position: fixed;
	display: none;
	/* margin: -15px -5px -20px -15px; */
	margin: -15px -10px -20px;
}
.spinnerContentRightLayer .spinner {
	display: block;
	margin: 10px auto;
}
.spinnerContentRightLayer.show {
	display: block;
	z-index: 1000;
}
.appMainContent.menuCollapsed .spinnerContentRightLayer,
.appMainContent.menuOverflow .spinnerContentRightLayer {
	width: calc(100% - var(--fk-side-menu-width-collapsed));
}

/****************/

.ddownBtn {
	font-size: 1rem;
	display: inline-block;
}
.ddownBtn button.dropdown-toggle {
	color: var(--fk-color-btn-link-clickable);
	/* font-size: 0.7em; */
	border-color: var(--fk-color-btn-border);
	/* opacity: 0.8; */
	opacity: 1;
	background: none;
	box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0) !important;
	background-color: transparent !important;
	/* padding: 6px 8px 5px; */
	display: inline-flex;
	align-items: center;
}
.ddownBtn.old-btn button.dropdown-toggle {
	font-size: 0.7em;
	border-color: var(--fk-color-btn-link-clickable);
	opacity: 0.8;
	padding: 6px 8px 5px;
}

.ddownBtn button.dropdown-toggle:not(:disabled):hover,
.ddownBtn button.dropdown-toggle:not(:disabled):focus,
.ddownBtn button.dropdown-toggle:not(:disabled):active,
.ddownBtn.show button.dropdown-toggle:not(:disabled) {
	color: var(--fk-color-btn-link-clickable) !important;
	border-color: var(--fk-color-btn-link-clickable) !important;
	background-color: transparent !important;
	opacity: 1;
}

.ddownBtn button.dropdown-toggle:disabled {
	color: var(--fk-color-disabled);
}

.ddownBtn button.dropdown-toggle::after,
.ddownBtn button.dropdown-toggle::before {
	display: none !important;
}

.ddownBtn button.dropdown-toggle .bi.bi-caret-down-fill {
	font-size: 10px;
}

.ddownBtn button.dropdown-toggle .spinner {
	font-size: 0.12em;
	margin: 0 4px;
}

.dropdown-menu {
	border-radius: 4px;
	padding: 5px 0;
	box-shadow: 0 4px 8px rgb(0, 0, 0, 0.12);
}
.dropdown-toggle::after {
	margin-left: 0.5em;
}

.ddownMenuItem,
.ddownMenuItem:hover,
.ddownMenuItem:focus,
.ddownMenuItem:active {
	font-size: 14px;
	color: var(--fk-color-btn-link-clickable);
	outline: none;
	display: flex;
	align-items: center;
}
.ddownMenuItem:hover,
.ddownMenuItem:focus,
.ddownMenuItem:active {
	background: #f1f5f8;
}
.ddownMenuItem.disabled,
.ddownMenuItem.disabled:hover,
.ddownMenuItem.disabled:focus,
.ddownMenuItem.disabled:active {
	color: #b8b8ba;
	background: none;
	cursor: default;
}
.ddownMenuItem > * {
	/* height: 1.2em; */
	line-height: 1.2em;
	display: inline-block !important;
	vertical-align: baseline !important;
}
.ddownMenuItem .bi,
.ddownMenuItem .isvg {
	margin-right: 10px;
}
.ddownMenuItem .isvg {
	height: 1.4em;
	width: 1.4em;
	text-align: center;
	display: inline-block;
}
.ddownMenuItem .isvg svg {
	height: 1.2em;
	width: 1.2em;
	margin: auto;
}
.ddownMenuItem .isvg svg path {
	fill: var(--fk-color-btn-link-clickable) !important;
}
.ddownMenuItem.disabled .isvg svg path {
	fill: #b8b8ba !important;
}

.ddownMenuItem i.bi {
	font-size: 1.4em;
}

.ddownMenuItem .newFeatureNotif {
	width: 32px;
	position: absolute;
	right: 5px;
}
.ddownMenuItem.disabled .newFeatureNotif {
	background: #b8b8ba;
}

/* Bannière essai gratuit */

.subscriptionBanner {
	height: 12px;
	width: 100%;
	font-size: 12px;
	font-weight: 600;
	line-height: 12px;
	padding: 0;
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
}
.subscriptionBanner.-old {
	top: calc(var(--fk-navbar-height) - 8px);
}
.freeTrialBanner {
	background: var(--fk-color-text-success-light);
}
.expiredBanner {
	background: #fdba00;
}
.freeTrialBanner span:hover {
	text-decoration: underline;
	cursor: pointer;
}
#freeTrialBannerPop .popover-body {
	text-align: center;
}

/******************/

.blured {
	color: transparent;
	text-shadow: 0 0 7px rgba(70, 70, 70, 0.5);
}

/******************/

.linkBtn {
	color: var(--fk-color-btn-link-clickable);
	font-weight: normal;
	padding: 0;
	text-decoration: none;
	outline-style: none;
	border: none;
	background: none;
	cursor: pointer;
}
.linkBtn:hover {
	color: var(--fk-color-btn-link-clickable);
}
.linkBtn:not(.noUnderline):not(:disabled):not(.disabled):hover {
	text-decoration: underline;
}
.linkBtn:disabled,
.linkBtn.disabled {
	color: var(--fk-color-disabled);
	cursor: default;
	text-decoration: none;
}
.linkBtn svg path {
	fill: var(--fk-color-btn-link-clickable) !important;
}

.linkBtn .spinner,
.spinner.buttonLoader {
	font-size: 0.12em;
	margin: 0 4px;
}

button.closeBtn {
	color: var(--fk-color-text-standard);
	/* font-size: 30px; */
	height: 28px;
	width: 28px;
	font-size: 28px;
	line-height: 28px;
	font-weight: normal;
	padding: 0;
	text-decoration: none;
	outline-style: none;
	border: none;
	background: none;
	cursor: pointer;
	opacity: 0.65;
	position: absolute;
	/* top: 0; */
	/* right: 10px; */
	top: 4px;
	right: 4px;
	z-index: 2;
}
button.closeBtn:hover {
	opacity: 1;
}

/******************/

.forbidden {
	padding-top: 100px;
	text-align: center;
	/* color: #FFFFFF; */
}

.newFeatureNotif {
	height: 14px;
	width: 50px;
	line-height: 9px;
	font-size: 8px;
	font-weight: bold;
	color: white;
	text-transform: uppercase;
	/* text-align: center; */
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background: #ec008c;
	/* padding: 3px; */
	display: flex !important;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 0;
	-webkit-box-shadow:
		0 1px 6px 0 rgba(255, 255, 255, 0.12),
		0 2px 4px -1px rgba(255, 255, 255, 0.2);
	-moz-box-shadow:
		0 1px 6px 0 rgba(255, 255, 255, 0.12),
		0 2px 4px -1px rgba(255, 255, 255, 0.2);
	box-shadow:
		0 1px 6px 0 rgba(255, 255, 255, 0.12),
		0 2px 4px -1px rgba(255, 255, 255, 0.2);
}
.newFeatureNotif.beta {
	width: 34px;
}
.newFeatureNotif.new {
	width: 50px;
}

/**/

.squareSwitch {
	/* width: 100%; */
	text-align: center;
	margin-bottom: 10px;
}
.squareSwitch .switch {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}
.squareSwitch.w2 .switch {
	width: 195px;
}
.squareSwitch.w3 .switch {
	width: 300px;
}
.squareSwitch .switch label {
	height: 50px;
	width: 90px;
	text-align: center;
	border: 1px solid #b8b8ba;
	border-radius: 4px;
	padding: 5px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}
.squareSwitch .switch label.checked {
	border-color: var(--fk-color-selection-selected);
	background: var(--fk-color-selection-selected);
}
.squareSwitch .switch label:not(.checked):hover {
	border-color: var(--fk-color-btn-link-clickable);
}
.squareSwitch .switch label input {
	display: none;
}
.squareSwitch .switch label span {
	width: 20px;
	display: block;
	margin: 0 auto;
}
.squareSwitch .switch label span svg {
	height: 20px;
	width: 20px;
}
.squareSwitch .switch label span svg path {
	fill: #b8b8ba !important;
}
.squareSwitch .switch label.checked span svg path {
	fill: #ffffff !important;
}
.squareSwitch .switch label:not(.checked):hover span svg path {
	fill: var(--fk-color-btn-link-clickable) !important;
}
.squareSwitch .switch label div {
	font-size: 12px;
	font-weight: normal;
	color: #b8b8ba;
}
.squareSwitch .switch label.checked div {
	color: #ffffff;
}
.squareSwitch .switch label:not(.checked):hover div {
	color: var(--fk-color-btn-link-clickable);
}

/***/

.connectForm .title {
	font-size: 20px;
	font-weight: lighter;
	text-transform: uppercase;
	color: var(--fk-color-text-standard);
	text-align: center;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.connectForm .title {
		font-size: 24px;
		margin-bottom: 30px;
	}
}

.connectForm .subtitle {
	font-size: 16px;
	font-weight: lighter;
	color: var(--fk-color-text-standard);
	text-align: center;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.connectForm .subtitle {
		font-size: 24px;
		margin-bottom: 30px;
	}
}

.connectForm .inputs {
	/* padding-bottom: 20px; */
	position: relative;
}

.connectForm form,
.connectForm input,
.connectForm select,
.connectForm textarea {
	text-align: center;
	text-align-last: center;
}

.connectForm .form-control {
	height: 40px;
	font-size: 16px;
	margin-bottom: 25px;
}
@media (min-width: 768px) {
	.connectForm .form-control {
		height: 50px;
		font-size: 20px;
		margin-bottom: 30px;
	}
}

.connectForm .inputGroup {
	padding-top: 15px;
	position: relative;
}

.connectForm .inputGroup .floatingLabel {
	width: 100%;
	font-size: 5vw;
	font-weight: normal;
	color: #b8b8ba;
	text-align: center;
	pointer-events: none;
	position: absolute;
	top: 25px;
	left: 0;
	-webkit-transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	transition: 0.2s ease all;
}
@media (min-width: 320px) {
	.connectForm .inputGroup .floatingLabel {
		font-size: 16px;
	}
}
@media (min-width: 768px) {
	.connectForm .inputGroup .floatingLabel {
		font-size: 20px;
		top: 27px;
	}
}

.connectForm input.labelled:focus ~ .floatingLabel,
.connectForm input.labelled:valid ~ .floatingLabel,
.connectForm textarea.labelled:focus ~ .floatingLabel,
.connectForm textarea.labelled:valid ~ .floatingLabel,
.connectForm select.labelled:focus ~ .floatingLabel,
.connectForm select.labelled:valid ~ .floatingLabel {
	font-size: 5vw;
	color: var(--fk-color-btn-link-clickable);
	top: -4px;
	left: 0px;
}
/* ne fonctionne pas si on met read-only avec le reste */
.connectForm input.labelled:read-only ~ .floatingLabel,
.connectForm textarea.labelled:read-only ~ .floatingLabel,
.connectForm select.labelled:read-only ~ .floatingLabel {
	font-size: 5vw;
	color: var(--fk-color-btn-link-clickable);
	top: -4px;
	left: 0px;
}
@media (min-width: 320px) {
	.connectForm input.labelled:focus ~ .floatingLabel,
	.connectForm input.labelled:valid ~ .floatingLabel,
	.connectForm textarea.labelled:focus ~ .floatingLabel,
	.connectForm textarea.labelled:valid ~ .floatingLabel,
	.connectForm select.labelled:focus ~ .floatingLabel,
	.connectForm select.labelled:valid ~ .floatingLabel {
		font-size: 12px;
	}
	.connectForm input.labelled:read-only ~ .floatingLabel,
	.connectForm textarea.labelled:read-only ~ .floatingLabel,
	.connectForm select.labelled:read-only ~ .floatingLabel {
		font-size: 12px;
	}
}
@media (min-width: 768px) {
	.connectForm input.labelled:focus ~ .floatingLabel,
	.connectForm input.labelled:valid ~ .floatingLabel,
	.connectForm textarea.labelled:focus ~ .floatingLabel,
	.connectForm textarea.labelled:valid ~ .floatingLabel,
	.connectForm select.labelled:focus ~ .floatingLabel,
	.connectForm select.labelled:valid ~ .floatingLabel {
		font-size: 16px;
		top: -9px;
	}
	.connectForm input.labelled:read-only ~ .floatingLabel,
	.connectForm textarea.labelled:read-only ~ .floatingLabel,
	.connectForm select.labelled:read-only ~ .floatingLabel {
		font-size: 16px;
		top: -9px;
	}
}

.connectForm input.labelled,
.connectForm textarea.labelled,
.connectForm select.labelled {
	border-color: #e3e7eb;
	box-shadow: none;
}
.connectForm input.labelled:focus,
.connectForm textarea.labelled:focus,
.connectForm select.labelled:focus {
	border-color: var(--fk-color-btn-link-clickable);
}
.connectForm input.labelled:read-only,
.connectForm textarea.labelled:read-only,
.connectForm select.labelled:read-only {
	border-color: #e3e7eb;
	background: none;
}

.connectForm .inputError {
	width: 100%;
	/* max-width: 400px; */
	font-size: 3vw;
	color: var(--fk-color-error);
	text-align: center;
	position: absolute;
	bottom: -20px;
	left: 0;
	z-index: 1;
}
@media (min-width: 425px) {
	.connectForm .inputError {
		font-size: 12px;
	}
}
@media (min-width: 768px) {
	.connectForm .inputError {
		font-size: 13px;
	}
}
.connectForm .inputError .bi {
	font-size: 13px;
}
.connectForm .inputError .infotip {
	color: var(--fk-color-error);
}

/**/

.connectModalDialog {
	width: 95%;
	max-width: 400px;
	margin-top: 7vh;
	margin-left: auto;
	margin-right: auto;
}

.connectModalDialog.join {
	width: 95%;
	max-width: 560px;
	margin-top: 5vh;
}

.connectModalDialog.thankYou {
	width: 95%;
	max-width: 500px;
	margin-top: 20vh;
}

.connectModalDialog.follow {
	width: 95%;
	max-width: 500px;
	margin-top: 3vh;
}
@media (min-width: 768px) {
	.connectModalDialog.follow {
		margin-top: 20vh;
	}
}

.connectModalDialog .modal-content {
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}

.connectModalDialog .modal-body {
	text-align: center;
	padding: 20px;
}
@media (min-width: 768px) {
	.connectModalDialog .modal-body {
		padding: 30px 40px;
	}
}

.connectModalDialog .title {
	font-size: 20px;
	font-weight: lighter;
	text-transform: uppercase;
	color: var(--fk-color-text-standard);
	text-align: center;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.connectModalDialog .title {
		font-size: 24px;
		margin-bottom: 30px;
	}
}

.connectModalDialog .subtitle {
	font-size: 16px;
	font-weight: lighter;
	color: var(--fk-color-text-standard);
	text-align: center;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.connectModalDialog .subtitle {
		font-size: 24px;
		margin-bottom: 30px;
	}
}

.connectModalDialog form,
.connectModalDialog input,
.connectModalDialog select,
.connectModalDialog textarea {
	text-align: center;
	text-align-last: center;
}

.connectModalDialog .form-control {
	height: 40px;
	font-size: 16px;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.connectModalDialog .form-control {
		height: 50px;
		font-size: 20px;
		margin-bottom: 20px;
	}
}

/**/

.srcBPItemCopy ~ div {
	transform: none !important;
}
.srcBPItemCopy ~ [data-rbd-placeholder-context-id] {
	display: none !important;
}

/**/

/**/

.bsSelector {
	height: 24px;
	margin: 0 10px;
}
.bsSelector input[type="radio"] {
	visibility: hidden;
	display: block;
	height: 0;
	width: 0;
	position: absolute;
	overflow: hidden;
}

.bsSelector:not(.disabled):not(.readOnly):hover,
.bsSelector:not(.disabled):not(.readOnly) > div:hover,
.bsSelector:not(.disabled):not(.readOnly) input[type="radio"]:not([readonly]) + div:hover {
	cursor: pointer;
}

.bsSelector svg {
	height: 24px;
	width: 24px;
}

.bsSelector svg circle.button-outline {
	stroke: var(--fk-color-btn-link-clickable) !important;
}
.bsSelector.disabled svg circle.button-outline {
	stroke: #e3e7eb !important;
}

.bsSelector input[type="radio"][readonly] + div svg circle.button-outline {
	stroke: #b8b8ba !important;
}
.bsSelector.disabled input[type="radio"][readonly] + div svg circle.button-outline {
	stroke: #b8b8ba !important;
}

.bsSelector input[type="radio"]:checked + div svg circle.button-outline {
	/* stroke: #2B5E93 !important; */
	stroke: var(--fk-color-selection-selected) !important;
}
.bsSelector.disabled input[type="radio"]:checked + div svg circle.button-outline {
	stroke: #b8b8ba !important;
}

.bsSelector svg circle.button-center {
	fill: none !important;
}

.bsSelector input[type="radio"]:checked + div svg circle.button-center {
	/* fill: #2B5E93 !important; */
	fill: var(--fk-color-selection-selected) !important;
}
.bsSelector.disabled input[type="radio"]:checked + div svg circle.button-center {
	fill: #b8b8ba !important;
}

/**/

.statusTag {
	/* height: 20px; */
	/* width: 80px; */
	font-size: 11px;
	line-height: 1em;
	padding: 0.2em 1em;
	border-radius: 1em;
	border: 1px solid #f1f5f8;
	text-transform: uppercase;
	white-space: nowrap;
}
.statusTag.unsubscribed,
.statusTag.expired,
.statusTag.inactive {
	color: #b8b8ba;
	border-color: #b8b8ba;
}
.statusTag.pending {
	color: #fdba00;
	border-color: #fdba00;
}
.statusTag.active {
	color: var(--fk-color-text-success);
	border-color: var(--fk-color-text-success);
}
.statusTag.neutral,
.statusTag.suspended {
	color: var(--fk-color-text-emphasis);
	border-color: var(--fk-color-text-emphasis);
}
.statusTag.canceled,
.statusTag.error {
	color: var(--fk-color-error);
	border-color: var(--fk-color-error);
}
.statusInfo.unsubscribed,
.statusInfo.expired,
.statusInfo.inactive {
	color: var(--fk-color-inactive);
}
.statusInfo.pending {
	color: #fdba00;
}
.statusInfo.active {
	color: var(--fk-color-text-success);
}
.statusInfo.success {
	color: var(--fk-color-text-success);
}
.statusInfo.emphasis {
	color: var(--fk-color-text-emphasis);
}
.statusInfo.suspended {
	color: var(--fk-color-text-emphasis);
}
.statusInfo.canceled,
.statusInfo.error {
	color: var(--fk-color-error);
}
.statusInfo.warning {
	color: var(--fk-color-warning);
}

/**/

.multiPositionSwitch {
	margin-bottom: 10px;
}
.multiPositionSwitch .switches {
	height: 36px;
	width: 500px;
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-left: 15px;
}
.multiPositionSwitch .switches label {
	height: 36px;
	font-weight: 600;
	padding: 5px 5px 0;
	position: absolute;
	top: 0;
	cursor: pointer;
	z-index: 2;
	-webkit-transition: color 0.2s ease;
	-moz-transition: color 0.2s ease;
	-ms-transition: color 0.2s ease;
	-o-transition: color 0.2s ease;
	transition: color 0.2s ease;
}
.multiPositionSwitch .switches.positions3 label {
	width: 33.333333%;
}
.multiPositionSwitch .switches.positions2 label {
	width: 50%;
}
.multiPositionSwitch .switches.positions1 label {
	width: 100%;
}
.multiPositionSwitch .switches label:nth-of-type(1) {
	left: 0;
}
.multiPositionSwitch .switches.positions3 label:nth-of-type(2) {
	left: 33.333333%;
}
.multiPositionSwitch .switches.positions3 label:nth-of-type(3) {
	left: 66.666666%;
}
.multiPositionSwitch .switches.positions2 label:nth-of-type(2) {
	left: 50%;
}
.multiPositionSwitch .switches input:checked + label {
	color: white;
}
.multiPositionSwitch .switches input:not(:checked) + label {
	color: #b8b8ba;
}
.multiPositionSwitch .switches input:not(:checked):not(:disabled) + label:hover {
	color: var(--fk-color-btn-link-clickable);
}
.multiPositionSwitch .switches input {
	display: none;
}
.multiPositionSwitch .switches .switchPosition {
	height: 36px;
	background: var(--fk-color-selection-selected);
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-transition: left 0.2s ease;
	-moz-transition: left 0.2s ease;
	-ms-transition: left 0.2s ease;
	-o-transition: left 0.2s ease;
	transition: left 0.2s ease;
	position: absolute;
	top: -1px;
	left: 0;
	z-index: 1;
	margin: 0 1%;
}
.multiPositionSwitch .switches.positions3 .switchPosition {
	width: 31.333333%;
}
.multiPositionSwitch .switches.positions2 .switchPosition {
	width: 48%;
}
.multiPositionSwitch .switches.positions1 .switchPosition {
	width: 96%;
}
.multiPositionSwitch .switches.positions3 input:checked:nth-of-type(2) ~ .switchPosition {
	left: 33.333333%;
}
.multiPositionSwitch .switches.positions3 input:checked:nth-of-type(3) ~ .switchPosition {
	left: 66.666666%;
}
.multiPositionSwitch .switches.positions2 input:checked:nth-of-type(2) ~ .switchPosition {
	left: 50%;
}

/**/

.scrollable {
	overflow-y: auto;
}

@supports not selector(::-webkit-scrollbar) {
	.scrollable {
		scrollbar-width: thin;
	}
}
@supports selector(::-webkit-scrollbar) {
	.scrollable::-webkit-scrollbar {
		height: 8px;
		width: 6px;
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color: #f5f5f5;
	}
	.scrollable.thinScrollbar::-webkit-scrollbar {
		height: 6px;
		width: 4px;
	}
	.scrollable::-webkit-scrollbar-thumb {
		border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		background-color: #b8b8ba;
	}
}

/*
.scrollable {
	overflow-y: auto;
	--scrollbar-color-thumb: #B8B8BA;
	--scrollbar-color-track: #F5F5F5;
	--scrollbar-width: auto;
	--scrollbar-width-thin: thin;
	--scrollbar-height-legacy: 8px;
	--scrollbar-width-legacy: 6px;
	--scrollbar-height-legacy-thin: 6px;
	--scrollbar-width-legacy-thin: 4px;
}
.scrollable::-webkit-scrollbar {
	border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
*/

/* Modern browsers with `scrollbar-*` support */
/*
@supports (scrollbar-width: auto) {
	.scrollable {
		scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
		scrollbar-width: var(--scrollbar-width);
	}
	.scrollable.thinScrollbar {
		scrollbar-width: var(--scrollbar-width-thin);
	}
}
*/

/* Legacy browsers with `::-webkit-scrollbar-*` support */
/*
@supports selector(::-webkit-scrollbar) {
	.scrollable::-webkit-scrollbar-thumb {
		background: var(--scrollbar-color-thumb);
	}
	.scrollable::-webkit-scrollbar-track {
		background: var(--scrollbar-color-track);
	}
	.scrollable::-webkit-scrollbar {
		max-height: var(--scrollbar-height-legacy);
		max-width: var(--scrollbar-width-legacy);
	}
	.scrollable.thinScrollbar::-webkit-scrollbar {
		max-height: var(--scrollbar-height-legacy-thin);
		max-width: var(--scrollbar-width-legacy-thin);
	}
}
*/

/**/

.helpDeskZone {
	height: 100px;
	width: 100px;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: -1;
}

/**/

.paramSelector input[type="radio"] {
	height: 0;
	width: 0;
	overflow: hidden;
	visibility: hidden;
	/* display: block; */
	position: absolute;
}
.paramSelector div {
	border: 1px solid #e3e7eb;
	border-radius: 5px;
	text-align: center;
	color: var(--fk-color-medium-grey);
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.paramSelector input[type="radio"]:disabled + div {
	color: var(--fk-color-disabled);
}
.paramSelector input[type="radio"]:not(:checked):not(:disabled) + div:hover {
	border-color: var(--fk-color-btn-link-clickable);
	color: var(--fk-color-btn-link-clickable);
	cursor: pointer;
}

.paramSelector input[type="radio"]:checked + div {
	border: 2px solid var(--fk-color-selection-selected);
	color: var(--fk-color-selection-selected);
	padding: 4px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.paramSelector2 {
	cursor: pointer;
}
.paramSelector2:hover > div {
	box-shadow: var(--fk-shadow-md);
}
.paramSelector2 input {
	visibility: hidden;
	display: block;
	height: 0;
	width: 0;
	position: absolute;
	overflow: hidden;
}
.paramSelector2 > div {
	height: 120px;
	width: 150px;
	text-align: center;
	padding: 20px 10px;
	border: 1px solid var(--fk-color-btn-link-clickable);
	border-radius: 15px;
	background: none;
}
.paramSelector2 .labelIcon {
	height: 30px;
	margin: 0 auto;
}
.paramSelector2 .labelIcon svg {
	height: 30px;
	width: auto;
}
.paramSelector2 .labelIcon svg path {
	fill: var(--fk-color-btn-link-clickable) !important;
}
.paramSelector2 .labelText {
	height: 45px;
	font-size: 14px;
	color: var(--fk-color-btn-link-clickable);
	/* text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.paramSelector2 input:checked + div {
	border-color: var(--fk-color-selection-selected);
	background: var(--fk-color-selection-selected);
	box-shadow: var(--fk-shadow-lg);
}
.paramSelector2 input:checked + .labelIcon svg path,
.paramSelector2 input:checked + div .labelIcon svg path {
	fill: white !important;
}
.paramSelector2 input:checked + .labelText,
.paramSelector2 input:checked + div .labelText {
	color: white;
}

/**/

.bottomLeftActionsWrapper-old {
	padding: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1099;
}

.appMainContent.menuCollapsed .bottomLeftActionsWrapper-old:hover {
	background: #16395f;
}

.bottomLeftActionsWrapper-old > i {
	color: white;
}
.appMainContent.menuExpanded .bottomLeftActionsWrapper-old > i {
	display: none;
}
.appMainContent.menuExpanded .bottomLeftActionsWrapper-old > div {
	display: flex;
	justify-content: flex-start;
}
.appMainContent.menuCollapsed .bottomLeftActionsWrapper-old:hover > i {
	display: none;
}
.appMainContent.menuCollapsed .bottomLeftActionsWrapper-old > div {
	display: none;
}
.appMainContent.menuCollapsed .bottomLeftActionsWrapper-old:hover > div {
	display: flex;
	justify-content: flex-start;
}
.bottomLeftActionsWrapper-old > div > * {
	margin-right: 5px;
}
.bottomLeftActionsWrapper-old .bottomLeftActionsBtn {
	height: 32px;
	width: 32px;
	color: white;
	background: none;
	border: 1px solid white;
	outline: none;
	padding: 0;
	text-align: center;
	border-radius: 50%;
}
.bottomLeftActionsWrapper-old .bottomLeftActionsBtn:hover {
	border: 1px solid var(--fk-color-selection-selected);
	background: var(--fk-color-selection-selected);
}

.bottomLeftActionsTooltip {
	font-size: 13px;
}
.bottomLeftActionsTooltip .simpleTooltipArrow {
	display: none;
}

.bottomLeftActionsWrapper {
	padding: 5px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1009;
	transition: width 0.6s ease;
}
.appMainContent.menuExpanded .bottomLeftActionsWrapper {
	width: var(--fk-side-menu-width-expanded);
	border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.appMainContent.menuCollapsed .bottomLeftActionsWrapper {
	width: var(--fk-side-menu-width-collapsed);
}
.appMainContent:not(.menuCollapsed) .bottomLeftActionsWrapper .sideMenuItem.level2 {
	width: 100%;
	margin: 0;
	border: none;
}
.bottomLeftActionsWrapper .sideMenuItem button {
	opacity: 0.5;
}
.bottomLeftActionsWrapper .sideMenuItem button:hover {
	opacity: 1;
}

/**/

.noDataPlaceholder {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/**/

.productDescriptor.productTag {
	height: 20px;
	width: auto;
	font-size: 13px;
	font-weight: 500;
	color: var(--fk-color-text-standard);
	border-radius: 14px;
	padding: 0;
	text-align: center;
	display: inline-flex;
	align-items: center;
}
.productDescriptor.productTag.lightBorder {
	border: 1px solid #e3e7eb;
}
.productDescriptor.productTag .productName {
	max-width: 90px;
	text-align: left;
	padding: 2px 8px 1px 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.productDescriptor.productTag .productContribution {
	max-width: 40px;
	font-size: 10px;
	text-align: right;
	padding: 4px 6px 2px 5px;
	border-left: 1px solid white;
}

/**/

.defaultTransition.transition-enter {
	position: static;
	opacity: 0;
}
.defaultTransition.transition-enter-active {
	opacity: 1;
	transition: opacity 600ms ease;
}
.defaultTransition.transition-enter-done {
	position: static;
}
.defaultTransition.transition-exit {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	transition: none;
}
.defaultTransition.transition-exit-active {
	opacity: 0;
}

/**/

.fileUploaderModalDialog {
	width: 600px;
	margin-top: 6vh;
}
.fileUploaderModalDialog .modal-footer {
	border: none;
}

.fileUploaderModalDialog .uploadFileBox {
	width: 420px;
	text-align: center;
	margin: 0 auto;
}
.fileUploaderModalDialog .uploadFileBox .icon {
	text-align: center;
}
.fileUploaderModalDialog .uploadFileBox .text {
	position: relative;
	display: block;
	margin: 0 auto;
	text-align: center;
}
.fileUploaderModalDialog .uploadFileBox .b-main-action {
	width: 200px;
	display: block;
	margin: 10px auto 0 auto;
}

.fileUploaderModalDialog .uploadFileBox .uploadBtn {
	height: 70px;
	width: 200px;
	display: block;
	margin: 10px auto 0 auto;
	position: relative;
}

.fileUploaderModalDialog .uploadFileBox .uploadBtn .step {
	font-size: 24px;
	font-weight: 600;
}
.fileUploaderModalDialog .uploadFileBox .uploadBtn .btnIcon {
	font-size: 16px;
	margin-right: 15px;
}

button.uploadBtn .uploadBtnHelper {
	height: 100%;
	width: 120px;
	font-size: 0.9em;
	color: var(--fk-color-error);
	text-align: left;
	white-space: normal;
	position: absolute;
	top: 0;
	left: calc(100% + 1em);
	display: none;
	align-items: center;
	justify-content: flex-start;
}
button.uploadBtn:disabled:hover .uploadBtnHelper,
button.uploadBtn:disabled:focus .uploadBtnHelper {
	display: flex;
}

.fileUploaderModalDialog .uploadFileBox .fileName {
	height: 26px;
	width: 100%;
	font-size: 13px;
	padding-top: 5px;
	color: var(--fk-color-selection-selected);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.fileUploaderModalDialog .spinner.waitForFileUploading {
	font-size: 2.6px;
	color: var(--fk-color-btn-link-clickable);
	padding-top: 4px;
	border-left-color: transparent;
}

.fileUploaderModalDialog .fileObjInfo {
	width: 80%;
	text-align: left;
	display: inline-block;
	/* margin: 30px auto 40px; */
	margin: 10px auto;
}
.fileUploaderModalDialog .fileObjInfo .customRadioBtn {
	height: auto;
	/* display: block; */
	display: inline-block;
	margin-top: 15px;
}

label.uploadFile:not(:disabled):not(.disabled) {
	/* color: var(--fk-color-btn-link-clickable); */
	cursor: pointer;
}
/*
label.uploadFile.disabled {
	color: #b8b8ba;
}
label.uploadFile i {
	margin-right: 5px;
}
*/

/**/

.modalDialogInnerSection {
	font-size: 14px;
	padding: 10px 15px 15px;
	border: 1px solid #e3e7eb;
	border-radius: 6px;
	background: white;
}

/**/

.highcharts-label.highcharts-no-data text {
	color: rgba(var(--fk-color-text-standard-rgb), 0.7) !important;
	fill: rgba(var(--fk-color-text-standard-rgb), 0.7) !important;
	font-weight: normal !important;
}

/**/

.closeWarningModalDialog {
	width: 660px;
}
.closeWarningModalDialog .modal-body {
	text-align: center;
}

/**/

.consolidationPerimeterReport {
	border-radius: 6px;
	border: 1px solid #e3e7eb;
	padding: 10px;
	margin-bottom: 20px;
}
.consolidationPerimeterReport table {
	width: 100%;
	font-size: 14px;
	table-layout: fixed;
	margin-top: 10px;
}
.consolidationPerimeterReport table th {
	/* color: #597A96; */
	color: color-mix(in srgb, var(--fk-color-text-standard) 100%, white 60%);
	font-weight: 500;
	padding: 4px 8px;
	border-bottom: 1px solid #f1f5f8;
}
.consolidationPerimeterReport table td {
	padding: 4px 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.consolidationPerimeterReport table th.fixedWidth-120,
.consolidationPerimeterReport table td.fixedWidth-120 {
	width: 120px;
	text-align: center;
}
.consolidationPerimeterReport table th.fixedWidth-160,
.consolidationPerimeterReport table td.fixedWidth-160 {
	width: 160px;
	text-align: center;
}

/**/

.Toastify__progress-bar--info {
	background: var(--fk-color-medium-dark-grey) !important;
}
.Toastify__progress-bar--warning {
	background: var(--fk-color-warning) !important;
}

/**/

.saveBtn {
	position: relative;
}
.saveBtn .saveBtnHelper {
	width: 240px;
	color: var(--fk-color-error);
	text-align: left;
	white-space: normal;
	position: absolute;
	top: -2px;
	right: -250px;
	display: none;
}
.saveBtn:disabled:hover .saveBtnHelper,
.saveBtn:disabled:focus .saveBtnHelper {
	display: block;
}

/**/

.needsRefresh {
	width: 400px;
}

.modalBackdrop:after {
	content: " ";
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.15);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
}
