.popover-body {
	font-size: 14px;
	color: var(--fk-color-text-standard);
	overflow: hidden;
	text-overflow: ellipsis;
}
.popover-body .smallNote {
	font-size: 11px;
	font-style: italic;
}
@media (min-width: 1280px) {
	.popover-body {
		font-size: 16px;
	}
	.popover-body .smallNote {
		font-size: 12px;
	}
}

.popover-body .rogLight {
	height: 12px;
	width: 12px;
	margin: 3px 10px 3px 3px;
	background-color: var(--fk-color-disabled);
	border: none;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	float: left;
}

.popover-body .rogLight.red.on {
	background-color: #eb6b6f;
}

.popover-body .rogLight.orange.on {
	background-color: #fd7f05;
}

.popover-body .rogLight.green.on {
	background-color: var(--fk-color-text-success);
}

.popover-body a,
.popover-body a:focus,
.popover-body a:hover,
.popover-body a:visited {
	color: var(--fk-color-btn-link-clickable);
	text-decoration: none;
	outline: none;
	cursor: pointer;
}

.popover-body .textSvg {
	vertical-align: middle;
}
.popover-body .textSvg svg {
	height: 16px;
	max-width: 16px;
}
.popover-body .textSvg svg path {
	fill: var(--fk-color-btn-link-clickable) !important;
}

/**/

.equation {
	font-weight: 300;
	display: inline-block;
	vertical-align: baseline;
}
.equation > div {
	display: inline-block;
	vertical-align: middle;
}
.equation .fraction > div {
	text-align: center;
	border-top: 1px solid;
}
.equation .fraction > div:first-child {
	border-top: none;
}
