@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i&display=swap");

@font-face {
	font-family: "Carmen Sans Regular";
	src: url(./fonts/CarmenSans-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "Carmen Sans Medium";
	src: url(./fonts/CarmenSans-Medium.ttf) format("truetype");
}
@font-face {
	font-family: "Carmen Sans SemiBold";
	src: url(./fonts/CarmenSans-SemiBold.ttf) format("truetype");
}

:root {
	/* --fk-color-text-standard: #5C5656; */
	/* --fk-color-text-standard-rgb: 92, 86, 86; */
	--fk-color-text-standard: #21242f;
	--fk-color-text-standard-rgb: 33, 36, 47;
	--fk-color-text-dark-grey: #404040;
	/* --fk-color-btn-link-clickable: #129FDD; */
	/* --fk-color-btn-link-clickable-rgb: 18, 159, 221; */
	--fk-color-btn-link-clickable: #3379e6;
	--fk-color-btn-link-clickable-rgb: 51, 121, 230;
	/* --fk-color-btn-border: #e3e7eb; */
	--fk-color-btn-border: rgba(var(--fk-color-btn-link-clickable-rgb), 0.2);
	/* --fk-color-selection-selected: #5682B2; */
	/* --fk-color-selection-selected-rgb: 86, 130, 178; */
	--fk-color-selection-selected: #2534a8;
	--fk-color-selection-selected-rgb: 37, 52, 168;
	/* --fk-color-text-emphasis: #5682B2; */
	--fk-color-text-emphasis: #2534a8;
	--fk-color-text-emphasis-rgb: 37, 52, 168;
	--fk-color-text-success: #04bdad;
	--fk-color-text-success-rgb: 4, 189, 173;
	--fk-color-text-success-light: #90f0bc;
	--fk-color-inactive: #b8b8ba;
	--fk-color-disabled: #e3e7eb;
	--fk-color-disabled-rgb: 227, 231, 235;
	--fk-color-warning: #fd7f05;
	--fk-color-error: #eb6c6f;
	--fk-color-xlight-grey: #f1f5f8;
	--fk-color-light-grey: #b8b8ba;
	--fk-color-medium-grey: #94a8ba;
	--fk-color-medium-dark-grey: #597a96;
	--fk-color-category-assets: #04bdad;
	--fk-color-category-assets-rgb: 4, 189, 173;
	--fk-color-category-liabilities: #5682b2;
	--fk-color-category-liabilities-rgb: 86, 130, 178;
	--fk-color-category-revenues: #43e3e8;
	--fk-color-category-revenues-rgb: 67, 227, 232;
	--fk-color-category-expenses: #90f0bc;
	--fk-color-category-expenses-rgb: 144, 240, 188;
	--fk-color-category-other: #2b9bbc;
	--fk-color-category-other-rgb: 43, 155, 188;
	--fk-color-category-unit-variables: #fdf0a7;
	--fk-color-category-unit-variables-rgb: 253, 240, 167;
	/* --fk-color-category-revenues-darker: #18bfc4; */
	/* --fk-color-category-expenses-darker: #19b761; */
	--fk-color-category-cash: #3142c6;
	--fk-color-category-margin: #4aa3f0;
	--fk-shadow-xs: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
	--fk-shadow-sm: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 10%) 0px 1px 2px -1px;
	--fk-shadow-md: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 10%) 0px 2px 4px -2px;
	--fk-shadow-lg: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px;
	--fk-shadow-xl: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 10%) 0px 8px 10px -6px;
	--fk-shadow-2xl: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
	/* --fk-color-bright-blue: #3142C6; */
	--fk-navbar-color: #2534a8;
	/* --fk-navbar-color: #E3E7EB; */
	--fk-side-menu-main-bg-color: #3142c6;
	--fk-side-menu-secondary-bg-color: #18107f;
	--fk-side-menu-emphasis-color: #838edd;
	/* --fk-side-menu-emphasis-color-rgb: 131, 142, 221; */
	--fk-side-menu-width-expanded: 245px;
	--fk-side-menu-width-collapsed: 60px;
	--fk-navbar-height: 0px;
	--fk-app-content-right-padding-top: 15px;
	--fk-app-content-right-padding-bottom: 20px;
	--fk-button-vertical-padding: 0.2em;
	--fk-button-horizontal-padding: 0.5em;
	--fk-default-line-height: 1.5;
	
	--toastify-text-color-light: var(--fk-color-text-standard);
	--toastify-color-dark: var(--fk-color-text-standard);
	--toastify-color-info: var(--fk-color-text-emphasis);
	--toastify-color-success: var(--fk-color-text-success);
	--toastify-color-warning: var(--fk-color-warning);
	--toastify-color-error: var(--fk-color-error);
}

body {
	font-family: "Roboto", "CircularStd-Medium", "Segoe UI", "Helvetica Neue", "Open Sans", Arial, "sans-serif";
	font-size: 16px;
	color: var(--fk-color-text-standard);
	background-color: #f1f5f8;
	/* background-color: var(--fk-color-darker-blue); */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* overflow: hidden; */
}

* {
	box-sizing: border-box;
	outline-style: none;
}

button,
input,
textarea,
select,
table,
.table {
	color: inherit;
	font-weight: inherit;
}

.semiBold {
	font-weight: 500 !important;
}

p {
	margin-bottom: 0.9rem;
}

.form-control {
	/* font-size: 16px; */
	font-size: 14px;
	color: var(--fk-color-text-standard);
	border-color: #e3e7eb;
	box-shadow: none !important;
	outline-style: none !important;
	background: none;
}
.form-control:hover,
.form-control:focus {
	border-color: var(--fk-color-btn-link-clickable);
	background: #ffffff;
}
.form-control:disabled {
	border-color: var(--fk-color-disabled);
	background: none;
}
.form-control[readonly] {
	border-color: var(--fk-color-disabled);
	background: none;
}
.form-control::placeholder {
	color: var(--fk-color-inactive);
}
.form-control::placeholder {
	color: var(--fk-color-inactive);
}
.form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--fk-color-inactive);
}
.form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: var(--fk-color-inactive);
}
.form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: var(--fk-color-inactive);
}
.form-control:-moz-placeholder {
	/* Firefox 18- */
	color: var(--fk-color-inactive);
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
	line-height: 1.2em;
}

.spinning.bi {
	margin-right: 7px;
	top: 2px;
	animation: spin 1.5s infinite linear;
}

@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}
	to {
		transform: scale(1) rotate(360deg);
	}
}
