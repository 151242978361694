.colorPicker {
	display: inline-block;
	position: relative;
}
.colorPickerSwatch {
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}
.colorPickerSwatchColor {
	height: 14px;
	width: 36px;
	border-radius: 2px;
}
.colorPickerBackdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.colorPickerPopover {
	position: absolute;
	z-index: 2;
}

.colorPicker .chrome-picker {
	font-family: inherit !important;
}
