.editableTextArea {
	min-height: 120px;
	width: 100%;
	border: 1px solid transparent;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	outline: none;
	background: none;
	padding: 0px 5px;
	margin: 0 -5px; /*10px -5px;*/
	display: block;
	overflow: visible;
}

.editableTextArea.placeholderStyle {
	font-style: italic;
}

.editableTextArea:not([readonly]):hover {
	border: 1px solid var(--fk-color-btn-link-clickable);
	background-color: #f8fafc;
}

.editableTextArea:not([readonly]):focus,
.editableTextArea:not([readonly]):active {
	border: 1px solid var(--fk-color-btn-link-clickable);
	background: none;
	/* margin-bottom: 0; */
}

.editableTextArea + .maxCharacters {
	height: 10px;
	width: 100%;
	text-align: right;
	font-size: 10px;
	margin: 0;
	/* display: none; */
	visibility: hidden !important;
	position: relative;
}
.editableTextArea:not([readonly]):focus + .maxCharacters,
.editableTextArea:not([readonly]):active + .maxCharacters {
	/* background: red; */
	margin: 0 -5px;
	/* display: block; */
	visibility: visible !important;
}
.editableTextArea + .maxCharacters > div {
	font-size: 10px;
	font-weight: normal;
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	margin: 0;
}
.editableTextArea:not([readonly]):focus + .maxCharacters > div,
.editableTextArea:not([readonly]):active + .maxCharacters > div {
	display: block;
}

.editableTextField {
	height: 30px;
	width: 80%;
	border: none;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	outline: none;
	background: none;
	padding: 1px 6px;
	margin-left: 10px;
	display: inline-block;
	/*overflow: visible;*/
}

.editableTextField:not([readonly]):hover,
.editableTextField:not([readonly]):focus,
.editableTextField:not([readonly]):active {
	border: 1px solid var(--fk-color-btn-link-clickable);
	/* background-color: #F8FAFC; */
	padding: 0 5px;
}
.editableTextField:not([readonly]):focus,
.editableTextField:not([readonly]):active {
	background-color: #ffffff;
}

.editableTextField + .maxCharacters {
	height: 10px;
	width: 100%;
	text-align: right;
	font-size: 10px;
	margin: 0;
	/* display: none; */
	visibility: hidden !important;
	position: relative;
}
.editableTextField:not([readonly]):focus + .maxCharacters,
.editableTextField:not([readonly]):active + .maxCharacters {
	/* background: red; */
	margin: 0 -5px;
	/* display: block; */
	visibility: visible !important;
}
.editableTextField + .maxCharacters > div {
	font-size: 10px;
	font-weight: normal;
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	margin: 0;
}
.editableTextField:not([readonly]):focus + .maxCharacters > div,
.editableTextField:not([readonly]):active + .maxCharacters > div {
	display: block;
}


.editableTextField2 input {
	height: 30px;
	width: 100%;
	border: none;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	outline: none;
	background: none;
	padding: 1px 6px;
	display: inline-block;
}

.editableTextField2 input:not([readonly]):hover,
.editableTextField2 input:not([readonly]):focus,
.editableTextField2 input:not([readonly]):active {
	border: 1px solid var(--fk-color-btn-link-clickable);
	padding: 0 5px;
}
.editableTextField2 input:not([readonly]):focus,
.editableTextField2 input:not([readonly]):active {
	background-color: #ffffff;
}

.editableTextField2 input + .maxCharacters {
	height: 10px;
	width: 100%;
	text-align: right;
	font-size: 10px;
	margin: 0;
	/* display: none; */
	visibility: hidden !important;
	position: relative;
}
.editableTextField2 input:not([readonly]):focus + .maxCharacters,
.editableTextField2 input:not([readonly]):active + .maxCharacters {
	/* background: red; */
	margin: 0 -5px;
	/* display: block; */
	visibility: visible !important;
}
.editableTextField2 input + .maxCharacters > div {
	font-size: 10px;
	font-weight: normal;
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	margin: 0;
}
.editableTextField2 input:not([readonly]):focus + .maxCharacters > div,
.editableTextField2 input:not([readonly]):active + .maxCharacters > div {
	display: block;
}
