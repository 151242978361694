.scale {
	width: 100%;
	display: flex;
	justify-content: space-around;
}
.scale.choice {
	align-items: flex-start;
}
.scale.satisfaction {
	align-items: center;
}

.scale .rate {
	width: 20%;
	text-align: center;
}

.scale .rateBtn {
	/* height: 60px; */
	/* color: #B8B8BA; */
	padding: 0;
	text-decoration: none;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	margin: 0;
}
.scale.satisfaction .rateBtn {
	height: 65px;
	width: 80px;
	font-size: 36px;
	border: 2px solid transparent;
	opacity: 0.6;
	border-radius: 10px;
}
.scale.satisfaction .rate.checked .rateBtn {
	font-size: 40px;
	border-color: var(--fk-color-selection-selected);
	opacity: 1;
}
.scale.satisfaction .rate:not(.checked) .rateBtn:hover {
	border: 1px solid var(--fk-color-btn-link-clickable);
	opacity: 1;
	/* margin: 1px; */
}

.scale.choice .ratingBox {
	height: 30px;
	width: 30px;
}
.scale.choice .ratingBox {
	font-size: 24px;
	border: 1px solid #b8b8ba;
	border-radius: 50%;
	display: inline-block;
}
.scale.choice .ratingBox i {
	color: white;
	line-height: 1em;
	display: block;
}
.scale.choice .rate:not(.checked) .ratingBox:hover {
	border-color: var(--fk-color-btn-link-clickable);
}
.scale .rate.checked .ratingBox {
	border-color: var(--fk-color-selection-selected);
	background: var(--fk-color-selection-selected);
}

.scale .rate .ratingLabel {
	/*height: 30px;*/
	width: 100%;
	font-size: 16px;
	color: var(--fk-color-text-standard);
	font-weight: normal;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 10px;
}
