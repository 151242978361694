.helpDeskBtn {
	height: 40px;
	width: 40px;
	font-size: 20px;
	color: white;
	outline: none;
	background: var(--fk-color-btn-link-clickable);
	border: none;
	padding: 5px 10px;
	border-radius: 50%;
	display: block;
	opacity: 0.9;
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 999;
}
.helpDeskBtn:hover {
	opacity: 1;
}
